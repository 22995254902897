@font-face {
  font-family: CeraPro-Regular;
  font-weight: normal;
  font-style: normal;
  src: url( './assets/fonts/CeraPro-Regular/CeraPro-Regular.eot');
  src: url( './assets/fonts/CeraPro-Regular/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
      url( './assets/fonts/CeraPro-Regular/CeraPro-Regular.woff') format('woff'),
      url( './assets/fonts/CeraPro-Regular/CeraPro-Regular.woff2') format('woff2'),
      url( './assets/fonts/CeraPro-Regular/CeraPro-Regular.svg#svgFontName') format('svg'),
      url( './assets/fonts/CeraPro-Regular/CeraPro-Regular.otf') format("opentype"),
      url( './assets/fonts/CeraPro-Regular/CeraPro-Regular.ttf') format("truetype");
}

@font-face {
  font-family: 'CeraPro-Bold';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/CeraPro-Bold/CeraPro-Bold.eot');
  src: url('./assets/fonts/CeraPro-Bold/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/CeraPro-Bold/CeraPro-Bold.woff') format('woff'),
      url('./assets/fonts/CeraPro-Bold/CeraPro-Bold.svg#svgFontName') format('svg'),
      url('./assets/fonts/CeraPro-Bold/CeraPro-Bold.otf') format("opentype"),
      url('./assets/fonts/CeraPro-Bold/CeraPro-Bold.ttf') format("truetype");
}

@font-face {
  font-family: 'CeraPro-Black';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/CeraPro-Black/CeraPro-Black.eot');
  src: url('./assets/fonts/CeraPro-Black/CeraPro-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/CeraPro-Black/CeraPro-Black.woff') format('woff'),
      url('./assets/fonts/CeraPro-Black/CeraPro-Black.svg#svgFontName') format('svg'),
      url('./assets/fonts/CeraPro-Black/CeraPro-Black.otf') format("opentype"),
      url('./assets/fonts/CeraPro-Black/CeraPro-Black.ttf') format("truetype");
}

@font-face {
  font-family: 'CeraCondensedPro-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/CeraCondensedPro-Regular/CeraCondensedPro-Regular.eot');
  src: url('./assets/fonts/CeraCondensedPro-Regular/CeraCondensedPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/CeraCondensedPro-Regular/CeraCondensedPro-Regular.woff') format('woff'),
      url('./assets/fonts/CeraCondensedPro-Regular/CeraCondensedPro-Regular.svg#svgFontName') format('svg'),
      url('./assets/fonts/CeraCondensedPro-Regular/CeraCondensedPro-Regular.otf') format("opentype"),
      url('./assets/fonts/CeraCondensedPro-Regular/CeraCondensedPro-Regular.ttf') format("truetype");
}

@font-face {
  font-family: 'CeraCondensedPro-Black';
  font-weight: normal;
  font-style: normal;
  src: url('./assets/fonts/CeraCondensedPro-Black/CeraCondensedPro-Black.eot');
  src: url('./assets/fonts/CeraCondensedPro-Black/CeraCondensedPro-Black.eot?#iefix') format('embedded-opentype'),
      url('./assets/fonts/CeraCondensedPro-Black/CeraCondensedPro-Black.woff') format('woff'),
      url('./assets/fonts/CeraCondensedPro-Black/CeraCondensedPro-Black.svg#svgFontName') format('svg'),
      url('./assets/fonts/CeraCondensedPro-Black/CeraCondensedPro-Black.otf') format("opentype"),
      url('./assets/fonts/CeraCondensedPro-Black/CeraCondensedPro-Black.ttf') format("truetype");
}